import { ComparisonId, CourseContentActivityType } from '~/enums/course.enums';

export default defineNuxtRouteMiddleware(async to => {
  const { course, getActivity, setCourseId, getActivityUrl } = useCourseState();

  if (!course.value && to.params.courseId) {
    await setCourseId(to.params.courseId as string);
  }

  if (!course.value) {
    return;
  }

  // exit if course does not contain kickoff & mastery quizzes
  if (!course.value.kickoffAndMasteryQuizzesEnabled) {
    return;
  }

  const activityId = (() => {
    if (to.params.quizId) {
      return to.params.quizId as string;
    } else if (to.params.lessonId) {
      return to.params.lessonId as string;
    } else if (to.name === CourseContentActivityType.Comparison) {
      return ComparisonId.toString();
    } else {
      return null;
    }
  })();
  if (!activityId) {
    return;
  }

  const targetActivity = getActivity(activityId);
  if (!targetActivity) {
    return;
  }

  // kick-off quiz is always accessible
  if (targetActivity.type === CourseContentActivityType.KickoffQuiz) {
    return;
  }

  // if mastery quiz is completed redirect to comparison
  if (targetActivity.type === CourseContentActivityType.MasteryQuiz) {
    if (targetActivity.progress?.finished) {
      const activity = getActivity(ComparisonId.toString());
      if (!activity) {
        console.error('Couldn\'t find comparison activity. This course is broken', course.value.id);
        return;
      }

      const url = getActivityUrl(activity);
      return navigateTo(url);
    }
  }

  // if activity is locked always redirect it to course summary
  if (targetActivity.locked) {
    return navigateTo(`/courses/${course.value?.id}/summary`);
  }
});